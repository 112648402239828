
import { defineComponent } from 'vue';
import IconContract from '@/components/Icon/IconContract.vue';
import { numberToMoney } from '@/utils/index';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
export default defineComponent({
  components: {
    IconContract,
    SketetonLoader,
  },
  data() {
    return {
      numberToMoney,
    };
  },
  props: {
    contractInfo: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
});
