
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ContractInfo from './InvestInfoContract.vue';
import UserInfo from './InvestInfoUser.vue';
import RepaymentSchedule from './RepaymentSchedule.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
import { detailContract } from '@/apis/ApiContract';
import { mapMutations, mapGetters } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    ContractInfo,
    UserInfo,
    RepaymentSchedule,
    BaseButton,
  },
  created() {
    const contractId = this.$route.params.id;
    this.getInfoDetail(contractId);
  },
  computed: {
    ...mapGetters({
      contractInfo: 'getContractInfo',
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapMutations({
      setConTractInfo: 'setConTractInfo',
      setTransferInfo: 'setTransferInfo',
    }),
    handleInvest() {
      this.setTransferInfo(this.contractInfo);
      this.$router.push({
        name: constRouter.BANK_ACCOUNT.name,
      });
    },
    async getInfoDetail(contractId: any) {
      this.loading = true;
      const res = await detailContract(contractId);
      this.setConTractInfo(res.data);
      this.loading = false;
    },
  },
});
