
import { defineComponent } from 'vue';
import IconPath from '@/components/Icon/IconPath.vue';
import IconStart from '@/components/Icon/IconStart.vue';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
export default defineComponent({
  components: {
    IconPath,
    IconStart,
    SketetonLoader,
  },
  props: {
    borrowerInfo: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
});
